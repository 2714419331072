<template>
  <div>

    <!-- <div class="text-center my-5" v-if="overview.length">
      <b-spinner variant="primary" label="Spinning" style="width: 3rem; height: 3rem;"></b-spinner>
    </div> -->

    <b-row>

      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-between">
            <p>
              <strong>Pending Applications</strong> <br>
              <small>Campaigns and Influencers selection</small>
            </p>
            <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
              <b-icon
                icon="exclamation-circle"
                font-scale="2"
                variant="warning"
              ></b-icon>
            </div>
          </div>
          <div class="divheight"></div>
          <div class="d-flex justify-content-between align-items-end">
            <b-spinner
              v-if="loading"
              small
              variant="primary"
            ></b-spinner>
            <h3 v-else>{{ overview.pending_applications || 0 }}</h3>
            <b-badge
              to="/campaign/list"
              variant="info"
              class="d-flex justify-content-between align-items-center pl-2"
            >
              Campaigns
              <b-icon
                icon="arrow-right-short"
                font-scale="1.5"
              ></b-icon>
            </b-badge>
          </div>
        </b-card>
      </b-col>

      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-between">
            <p>
              <strong>Live Campaigns</strong> <br>
              <small>All live campaigns on website</small>
            </p>
            <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
              <b-icon
                icon="clock-history"
                font-scale="2"
                variant="success"
              ></b-icon>
            </div>
          </div>
          <div class="divheight"></div>
          <div class="d-flex justify-content-between align-items-end">
            <b-spinner
              v-if="loading"
              small
              variant="primary"
            ></b-spinner>
            <h3 v-else>{{ overview.live_campaigns || 0 }}</h3>
            <b-badge
              to="/campaign/list"
              variant="info"
              class="d-flex justify-content-between align-items-center pl-2"
            >
              Campaigns
              <b-icon
                icon="arrow-right-short"
                font-scale="1.5"
              ></b-icon>
            </b-badge>
          </div>
        </b-card>
      </b-col>

      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-between">
            <p>
              <strong>Pending Campaigns</strong> <br>
              <small>Campaigns created by brands</small>
            </p>
            <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
              <b-icon
                icon="exclamation-octagon"
                font-scale="2"
                variant="warning"
              ></b-icon>
            </div>
          </div>
          <div class="divheight"></div>
          <div class="d-flex justify-content-between align-items-end">
            <b-spinner
              v-if="loading"
              small
              variant="primary"
            >
              ></b-spinner>
            <h3 v-else>{{ overview.pending_campaigns || 0 }}</h3>
            <b-badge
              to="/campaign/list"
              variant="info"
              class="d-flex justify-content-between align-items-center pl-2"
            >
              Campaigns
              <b-icon
                icon="arrow-right-short"
                font-scale="1.5"
              ></b-icon>
            </b-badge>
          </div>
        </b-card>
      </b-col>

    </b-row>

    <b-row class="mt-4">
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-between">
            <p>
              <strong>Total Influencers</strong> <br>
              <small>Influencer sign-ups statistics</small>
            </p>
            <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
              <b-icon
                icon="person-check"
                font-scale="2"
                variant="success"
              ></b-icon>
            </div>
          </div>
          <div class="d-flex justify-content-between divheight align-items-center">
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Last 7 days: {{overview.recent_signups || 0}}</div>
              <div>Last Month: {{overview.lastMonthInfluencer || 0}}</div>
            </span>
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Admin: {{overview.adminInfluencer || 0}}</div>
              <div>Signup: {{overview.websiteInfluencer || 0}}</div>
            </span>
          </div>
          <div class="d-flex justify-content-between align-items-end">
            <div>
              <b-spinner
                v-if="loading"
                small
                variant="primary"
              ></b-spinner>
              <h3 v-else>{{ overview.totalInfluencer || 0 }}</h3>
            </div>
            <b-badge
              to="/influencer/list"
              variant="info"
              class="d-flex justify-content-between align-items-center pl-2"
            >
              Influencers
              <b-icon
                icon="arrow-right-short"
                font-scale="1.5"
              ></b-icon>
            </b-badge>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-between">
            <p>
              <strong>Total YouTuber</strong> <br>
              <small>Through admin and website signup</small>
            </p>
            <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
              <b-icon
                icon="youtube"
                font-scale="2"
                class="text-danger"
                variant="primary"
              ></b-icon>
            </div>
          </div>
          <div class="d-flex justify-content-between divheight align-items-center">
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Admin: {{overview.admin_yt || 0}}</div>
            </span>
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Signup: {{overview.signup_yt || 0}}</div>
            </span>
          </div>
          <div class="d-flex justify-content-between align-items-end">
            <b-spinner
              v-if="loading"
              small
              variant="primary"
            ></b-spinner>
            <h3 v-else>{{ overview.total_yt || 0 }}</h3>
            <!-- <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Admin: {{overview.admin_yt || 0}}</div>
              <div>Signup: {{overview.signup_yt || 0}}</div>
            </span> -->
            <b-badge
              to="/influencer/youtube"
              variant="info"
              class="d-flex justify-content-between align-items-center pl-2"
            >
              YouTubers
              <b-icon
                icon="arrow-right-short"
                font-scale="1.5"
              ></b-icon>
            </b-badge>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-between">
            <p>
              <strong>Total Instagrammer</strong> <br>
              <small>Through admin and website signup</small>
            </p>
            <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
              <b-icon
                icon="instagram"
                font-scale="2"
                class="text-danger"
                variant="primary"
              ></b-icon>
            </div>
          </div>
          <div class="d-flex justify-content-between divheight align-items-center">
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Admin: {{overview.admin_insta || 0}}</div>
            </span>
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Signup: {{overview.signup_insta || 0}}</div>
            </span>
          </div>
          <div class="d-flex justify-content-between align-items-end">
            <b-spinner
              v-if="loading"
              small
              variant="primary"
            >
              ></b-spinner>
            <h3 v-else>{{ overview.total_insta || 0 }}</h3>
            <!-- <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Admin: {{overview.admin_insta || 0}}</div>
              <div>Signup: {{overview.signup_insta || 0}}</div>
            </span> -->
            <b-badge
              to="/influencer/instagram"
              variant="info"
              class="d-flex justify-content-between align-items-center pl-2"
            >
              Instagrammer
              <b-icon
                icon="arrow-right-short"
                font-scale="1.5"
              ></b-icon>
            </b-badge>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-between">
            <p>
              <strong>Total Brand</strong> <br>
              <small>Brand sign-ups statistics</small>
            </p>
            <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
              <b-icon
                icon="briefcase"
                font-scale="2"
                variant="primary"
              ></b-icon>
            </div>
          </div>
          <div class="d-flex justify-content-between divheight align-items-center">
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Good: {{overview.goodBrand || 0}}</div>
              <div>Bad: {{overview.badBrand || 0}}</div>
            </span>
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Follow Up: {{overview.followupBrand || 0}}</div>
              <div>Pending: {{overview.pendingBrand || 0}}</div>
            </span>
          </div>
          <div class="d-flex justify-content-between align-items-end">
            <b-spinner
              v-if="loading"
              small
              variant="primary"
            >
              ></b-spinner>
            <h3 v-else>{{ overview.total_brand || 0 }}</h3>
            <!-- <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Good: {{overview.goodBrand || 0}}</div>
              <div>Bad: {{overview.badBrand || 0}}</div>
            </span>
            <span
              v-if="!loading"
              style="font-size:12px;"
            >
              <div>Follow Up: {{overview.followupBrand || 0}}</div>
              <div>Pending: {{overview.pendingBrand || 0}}</div>
            </span> -->
            <b-badge
              to="/brand/list"
              variant="info"
              class="d-flex justify-content-between align-items-center pl-2"
            >
              Brand
              <b-icon
                icon="arrow-right-short"
                font-scale="1.5"
              ></b-icon>
            </b-badge>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getOverview } from '@/api/dashboard'

export default {
  name: 'Home',
  data () {
    return {
      overview: [],
      loading: false
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      this.loading = true
      const response = await getOverview()
      this.loading = false
      this.overview = response.data
    }
  }
}
</script>

<style scoped>
  p {
    line-height: 20px;
    margin: 0 0 10px;
  }
  p small {
    font-size: 12px;
    color: #74788d;
  }
  .inforIcon {
    height: 40px;
    width: 40px;
    /* border: 1px solid #4c62e3; */
    border-radius: 50%;
  }
  h3 {
    margin: 10px 0 0;
    line-height: 32px;
  }
  .divheight {
    height: 30px;
  }
</style>
